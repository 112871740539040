<script lang="ts" setup>
const props = withDefaults(defineProps<{
    isOpen: boolean
    title?: string
    width?: string
    isLoading?: boolean
    closable?: boolean
}>(), {
    title: "",
    width: "500px",
    isLoading: false,
    closable: true
});

const emit = defineEmits([ "close" ]);

function closeModal (): void {
    emit("close");
}

watch(() => props.isOpen, value => {
    const app = document.querySelector<HTMLDivElement>("html");
    if (app) {
        app.style.overflowY = value ? "hidden" : "auto";
    }
});
</script>

<template>
    <div v-if="isOpen" class="modal-backdrop">
        <Loader v-show="isLoading"/>
        <div v-show="!isLoading" :style="{ width: width }" class="modal-content">
            <span v-if="closable" class="modal-close" @click="closeModal">
                <Icon class="text-white" icon="cancel" width="24px"/>
            </span>
            <div class="modal-header">
                <h3>
                    <slot name="title">
                        {{ title }}
                    </slot>
                </h3>
            </div>
            <div class="modal-body">
                <slot/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.modal-backdrop {
    @apply fixed inset-0 z-[999] bg-dark bg-opacity-[.74] transition-all duration-300
    flex items-center justify-center;

    .modal-content {
        @apply relative bg-white rounded-md transition-all duration-300 px-8 py-6;

        .modal-close {
            @apply absolute -top-2 -right-2 cursor-pointer p-2 border-light border rounded-full
            translate-x-full -translate-y-full transition-all duration-300 w-[42px] h-[42px]
            flex items-center justify-center;
        }

        .modal-header {
            @apply flex items-center justify-between mb-6 w-full;

            h3, h3 > span, h3 > .row > * {
                @apply text-[16px] font-bold font-nordique w-full flex items-center justify-center gap-2;

                i {
                    font-size: 1.25rem !important;
                }
            }
        }
    }

    .modal-body {
        p {
            @apply text-md text-dark text-center font-bold;

            a {
                @apply underline;
            }

            &:not(:last-child) {
                @apply mb-4;
            }
        }

        hr {
            @apply border-cream;
        }
    }
}
</style>
